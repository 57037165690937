import React from 'react'
import mergeClassNames from '../../../../util/mergeClassNames/mergeClassNames';
import CustomLink from '../../CustomLink/CustomLink';
import styles from './NavLink.module.css'

function NavLink({text, to, onClick}) {
  return (
    <CustomLink to={to} onClick={onClick} className={mergeClassNames(["normal-text-regular", styles.link])}>
        {text}
    </CustomLink>
  )
}

export default NavLink

