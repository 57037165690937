import React from "react";
import PropTypes from 'prop-types';
import mergeClassNames from "../../../util/mergeClassNames/mergeClassNames";
import styles from "./Button.module.css";

function Button({
  children,
  variant = "contained",
  size = "medium",
  type = "button",
  theme = "primary",
  fullWidth = false,
  loading = false, 
  disabled = false,
  ...props // Captures any additional props
}) {
  return (
    <button
      type={type}
      className={mergeClassNames(
        "normal-text-bold",
        styles.button,
        styles[variant],
        styles[size],
        styles[theme],
        fullWidth && styles.fullWidth
      )}
      disabled={loading || disabled} 
      {...props}
    >
      <span className={styles.content} style={{ visibility: loading ? 'hidden' : 'visible' }}>
        {children}
      </span>
      {loading && <span className={styles.spinner}></span>}
    </button>
  );
}

// Add PropTypes for validation
Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['contained', 'outlined']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  theme: PropTypes.oneOf(['primary', 'secondary']),
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Button;