import React, { useState } from "react";
import * as Sentry from '@sentry/react';
import { useNavigate } from "react-router-dom";
import LayoutSection from "../../../components/common/LayoutSection/LayoutSection";
import { ReactComponent as MailBoxImage } from "../../../assets/images/MailBox.svg";
import { ReactComponent as ThankYouImage } from "../../../assets/images/ThankYou.svg";
import { IconMail, IconUser } from "@tabler/icons-react";
import Input from "../../../components/common/Input/Input";
import Button from "../../../components/common/Button/Button";
import {
  nameIsValid,
  emailIsValid,
} from "../../../util/validation/inputValidation";
import { collectEmail } from "../../../util/collectionApi/collectionApi";
import FormAlert from "../../../components/common/FormAlert/FormAlert";
import ImageWithWrappingContent from "../../../components/common/ImageWithWrappingContent/ImageWithWrappingContent";
import styles from "./Newsletter.module.css";

function Newsletter() {
  const navigate = useNavigate();
  const [subscribed, setSubscribed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  // Centralized validation function
  const validateField = (name, value) => {
    switch (name) {
      case "firstName":
        return !nameIsValid(value) ? "Invalid characters in first name" : "";
      case "lastName":
        return !nameIsValid(value) ? "Invalid characters in last name" : "";
      case "email":
        return !emailIsValid(value) ? "Invalid email address" : "";
      default:
        return "";
    }
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Generalized blur handler
  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  // Handle subscribe button click
  const handleSubscribe = async () => {
    // Validate all fields (because the input could be empty, and the state updates are scheduled)
    const firstNameError = validateField("firstName", formData.firstName);
    const lastNameError = validateField("lastName", formData.lastName);
    const emailError = validateField("email", formData.email);

    // Update formErrors state
    setFormErrors({
      firstName: firstNameError,
      lastName: lastNameError,
      email: emailError,
    });

    // Check if there are any validation errors
    if (firstNameError || lastNameError || emailError) {
      return; // Exit the function if there are errors
    }

    setLoading(true);
    setSubscriptionError(null);
    try {
      await collectEmail(formData.email, formData.firstName, formData.lastName);
      setSubscribed(true);
    } catch (error) {
      console.error("Subscription failed:", error);
      setSubscriptionError(
        "Uh oh, something went wrong! Newsletter sign up failed. Please try again later."
      );
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  // Define the navigation function
  const handleNavigateHome = () => {
    navigate("/");
  };

  const signUpTitle = "Join Our Mailing List";
  const signUpSubText =
    "We’re still building WealthSphere! By joining our mailing list, you’ll get the latest updates, sneak peeks, and—most importantly—you’ll be among the first to know when we officially launch. Sign up today, and get ready to take control of your financial future.";
  const thankYouTitle = "Thank You For Subscribing";
  const thankYouSubText =
    "Thank you for joining our community! We’re excited to share our progress with you as we build WealthSphere. Keep an eye on your inbox for updates, sneak peeks, and our official launch announcement!";

  const signUpFormContent = (
    <div className={styles.actionContainer}>
      <div className={styles.formInputs}>
        <Input
          label="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleInputChange}
          onBlur={handleBlur}
          error={!!formErrors.firstName}
          errorText={formErrors.firstName}
          fullWidth
          startIcon={<IconUser size={20} stroke={2} />}
          placeholder="John"
          type="text"
        />
        <Input
          label="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          onBlur={handleBlur}
          error={!!formErrors.lastName}
          errorText={formErrors.lastName}
          fullWidth
          startIcon={<IconUser size={20} stroke={2} />}
          placeholder="Doe"
          type="text"
        />
        <Input
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          onBlur={handleBlur}
          error={!!formErrors.email}
          errorText={formErrors.email}
          fullWidth
          startIcon={<IconMail size={20} stroke={2} />}
          placeholder="example@domain.com"
          type="email"
        />
      </div>
      {subscriptionError && <div className={styles.formAlertContainer}>
        {subscriptionError && (
          <div className={styles.alert}>
            <FormAlert type="error" message={subscriptionError} />
          </div>
        )}
      </div>}
      <Button
        variant="contained"
        fullWidth
        size="large"
        theme="primary"
        loading={loading}
        onClick={handleSubscribe}
      >
        Subscribe
      </Button>
    </div>
  );

  const thankYouButton = (
    <div className={styles.actionContainer}>
      <Button
        variant="contained"
        fullWidth
        size="large"
        theme="primary"
        onClick={handleNavigateHome}
      >
        Return To Home
      </Button>
    </div>
  );

  return (
    <>
      <LayoutSection customClasses={[styles.section]}>
        <ImageWithWrappingContent
          title={subscribed ? thankYouTitle : signUpTitle}
          subText={subscribed ? thankYouSubText : signUpSubText}
          imageComponent={subscribed ? <ThankYouImage /> : <MailBoxImage />}
        >
          {subscribed ? thankYouButton : signUpFormContent}
        </ImageWithWrappingContent>
      </LayoutSection>
    </>
  );
}

export default Newsletter;
