import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../common/Button/Button";
import { ReactComponent as HeroImage } from "../../../assets/images/HeroImage506x368.svg";
import styles from "./Hero.module.css";

function Hero() {
  const navigate = useNavigate();

  return (
    <div className={styles.heroContainer}>
      <div className={styles.contentContainer}>
        <h1>Simplify Your Finances</h1>
        <p className={["normal-text-regular"]}>
          WealthSphere helps you create simple long-term financial blueprints
          that offer a clear, holistic view of your finances, helping you map
          out your financial journey year by year.
        </p>
        <Button
          onClick={() => navigate("/newsletter")}
          variant="contained"
          size="large"
          theme="primary"
        >
          Start Your Free Trial
        </Button>
      </div>
      <HeroImage className={styles.heroImage} />
    </div>
  );
}

export default Hero;
