import React, { useState } from "react";
import LandingPageDesktopNav from "./LandingPageDesktopNav/LandingPageDesktopNav";
import LandingPageMobileNav from "./LandingPageMobileNav/LandingPageMobileNav";
import FullPageModal from "../common/FullPageModal/FullPageModal";
import useViewport from "../../hooks/useViewport/useViewport";
import NavSectionContainer from "../common/FullPageModal/NavSectionContainer/NavSectionContainer";
import NavContentContainer from "../common/FullPageModal/NavContentContainer/NavContentContainer";
import NavLink from "../common/FullPageModal/NavLink/NavLink";

function LandingPageNav() {
  const [isNavModalOpen, setIsNavModalOpen] = useState(false);
  const { breakpoint } = useViewport();

  const handleNavOpen = () => setIsNavModalOpen(true);
  const handleNavClose = () => setIsNavModalOpen(false);

  return (
    <>
      {breakpoint === "L" ? (
        <LandingPageDesktopNav />
      ) : (
        <LandingPageMobileNav onMenuClick={handleNavOpen} />
      )}

      <FullPageModal isOpen={isNavModalOpen} onClose={handleNavClose}>
        <NavContentContainer>
          <NavSectionContainer sectionTitle="Explore">
            <NavLink onClick={handleNavClose} text="Home" to="/" />
            <NavLink onClick={handleNavClose} text="Pricing" to="/pricing" />
            <NavLink onClick={handleNavClose} text="Newsletter" to="/newsletter" />
          </NavSectionContainer>
          {/* <NavSectionContainer sectionTitle="Legal">
            <NavLink text="Terms" to="/" />
            <NavLink text="Privacy Policy" to="/" />
          </NavSectionContainer>
          <NavSectionContainer sectionTitle="Company">
            <NavLink text="Contact Us" to="/" />
          </NavSectionContainer> */}
        </NavContentContainer>
      </FullPageModal>
    </>
  );
}

export default LandingPageNav;
