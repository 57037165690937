import React from 'react';
import ReactDOM from 'react-dom';
import LayoutSection from '../LayoutSection/LayoutSection';
import styles from './FullPageModal.module.css';
import FullPageModalNav from './FullPageModalNav/FullPageModalNav';

const FullPageModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const modalContent = (
    <div className={styles.modalContainer}>
      <LayoutSection>
        <FullPageModalNav onMenuCloseClick={onClose} />
      </LayoutSection>
      <LayoutSection>
        <div className={styles.modalContent}>
          {children}
        </div>
      </LayoutSection>
    </div>
  );

  // Use the portal in the return statement
  return ReactDOM.createPortal(modalContent, document.getElementById('modal'));
};

export default FullPageModal;