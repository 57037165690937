/**
 * Utility function to dynamically merge class names.
 * Handles strings, arrays, and objects for conditional class names.
 *
 * @param {...(string|array|object)} args - Class names to merge.
 * @returns {string} A single string with all valid class names.
 *
 * @example
 * // Basic usage with strings:
 * // Combines multiple string class names into a single string.
 * mergeClassNames('btn', 'btn-primary'); // 'btn btn-primary'
 *
 * @example
 * // Using arrays of class names:
 * // Useful for dynamically managing class lists with conditional logic.
 * // Filters out falsy values and flattens the array.
 * mergeClassNames(['btn', isPrimary ? 'btn-primary' : '', 'btn-large']); // 'btn btn-primary btn-large'
 *
 * @example
 * // Conditional class names using objects:
 * // Ideal for including class names based on certain conditions.
 * // The object keys are class names, and the values are booleans indicating whether to include the class.
 * mergeClassNames({ btn: true, 'btn-primary': isPrimary, 'btn-large': size === 'large' }); // 'btn btn-primary btn-large'
 *
 * @example
 * // Combining all input types:
 * // Demonstrates flexibility by mixing strings, objects, and arrays.
 * // This approach allows complex class name logic to be handled succinctly.
 * mergeClassNames('btn', { 'btn-primary': true }, ['btn-large', false, 'active']); // 'btn btn-primary btn-large active'
 */

const mergeClassNames = (...args) => {
  const classes = [];

  args.forEach(arg => {
    if (typeof arg === 'string' && arg) {
      // If the argument is a non-empty string, add it to the classes array
      classes.push(arg);
    } else if (Array.isArray(arg)) {
      // If the argument is an array, flatten it and add non-falsy values to the classes array
      arg.forEach(item => {
        if (item) {
          classes.push(item);
        }
      });
    } else if (typeof arg === 'object' && arg !== null) {
      // If the argument is an object, add the key to the classes array if the value is truthy
      Object.keys(arg).forEach(key => {
        if (arg[key]) {
          classes.push(key);
        }
      });
    }
  });

  // Join all valid class names with a space and return the result
  return classes.join(' ');
};

export default mergeClassNames;