import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./Input.module.css";
import mergeClassNames from "../../../util/mergeClassNames/mergeClassNames";
import { IconExclamationCircle, IconInfoCircle } from "@tabler/icons-react";

const Input = ({
  label,
  hint,
  error,
  errorText,
  startIcon,
  endIcon,
  disabled,
  value,
  onChange,
  onBlur,
  customClasses,
  fullWidth = false,
  ...props
}) => {
  const [focused, setFocused] = useState(false);

  const handleBlur = (e) => {
    setFocused(false);
    if (onBlur) onBlur(e); // Call the passed `onBlur` prop
  };

  const handleFocus = () => {
    setFocused(true);
  };

  return (
    <div
      className={mergeClassNames(
        styles.inputWrapper,
        customClasses,
        { [styles.fullWidth]: fullWidth } // Apply fullWidth class if the prop is true
      )}
    >
      {label && (
        <label className={mergeClassNames(styles.label, "small-text-regular")}>
          {label}
        </label>
      )}
      <div
        className={mergeClassNames(styles.inputContainer, {
          [styles.focused]: focused,
          [styles.error]: error && !focused, // Show error state based on the `error` prop
          [styles.disabled]: disabled,
        })}
      >
        {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
        <input
          className={mergeClassNames(styles.input, "normal-text-regular")}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          value={value}
          onChange={onChange}
          {...props}
        />
        {endIcon && <span className={styles.endIcon}>{endIcon}</span>}
      </div>

      {(hint || errorText) && (
        <div
          className={mergeClassNames(
            "small-text-regular",
            styles.hintContainer
          )}
        >
          {!error && hint && (
            <>
              <IconInfoCircle
                size={14}
                stroke={2}
                className={styles.hintIcon}
              />
              <span className={styles.hintText}>{hint}</span>
            </>
          )}
          {error && errorText && (
            <>
              <IconExclamationCircle
                size={14}
                stroke={2}
                className={styles.errorIcon}
              />
              <span className={styles.errorHint}>{errorText}</span>
            </>
          )}
        </div>
      )}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  customClasses: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  fullWidth: PropTypes.bool,
};

export default Input;