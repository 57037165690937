import React from "react";
import { Outlet } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logos/WealthSphereLogo.svg";
import LayoutSection from "../../components/common/LayoutSection/LayoutSection";
import FooterCopyrightBar from "../../components/FooterCopyrightBar/FooterCopyrightBar";
import CustomLink from "../../components/common/CustomLink/CustomLink";
import styles from "./AuthLayout.module.css";

function AuthLayout() {
  return (
    <div className={styles.pageWrapper}>
      <main className={styles.main}>
        <LayoutSection customClasses={[styles.mainSection]}>
          <div className={styles.contentContainer}>
            <div className={styles.logoContainer}>
              <CustomLink to="/">
                <Logo className={styles.logo} />
              </CustomLink>
            </div>
            <div className={styles.outletContainer}>
              <Outlet />
            </div>
          </div>
        </LayoutSection>
      </main>
      <footer className={styles.footer}>
        <LayoutSection customClasses={[styles.footerCopyrightBarSection]}>
          <FooterCopyrightBar textColor="primary300" />
        </LayoutSection>
      </footer>
    </div>
  );
}

export default AuthLayout;
