import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logos/WealthSphereLogoAndName.svg";
import Button from "../../common/Button/Button";
import styles from "./LandingPageDesktopNav.module.css";
import CustomLink from "../../common/CustomLink/CustomLink";

function LandingPageDesktopNav() {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div>
        <CustomLink to="/">
          <Logo className={styles.logo} />
        </CustomLink>
      </div>
      <div className={styles.navLinks}>
        <CustomLink to="/" className="normal-text-bold">
          Home
        </CustomLink>
        <CustomLink to="/pricing" className="normal-text-bold">
          Pricing
        </CustomLink>
        <CustomLink to="/newsletter" className="normal-text-bold">
          Newsletter
        </CustomLink>
      </div>
      <div className={styles.navButtons}>
        <Button
          onClick={() => navigate("/newsletter")}
          variant="outlined"
          size="medium"
          theme="secondary"
        >
          Log In
        </Button>
        <Button
          onClick={() => navigate("/newsletter")}
          variant="contained"
          size="medium"
          theme="secondary"
        >
          Start Your Free Trial
        </Button>
      </div>
    </div>
  );
}

export default LandingPageDesktopNav;
