import React from "react";
import Hero from "../../../components/home/Hero/Hero";
import InfoVideo from "../../../components/home/InfoVideo/InfoVideo";
import HowItWorks from "../../../components/home/HowItWorks/HowItWorks";
import CTA from "../../../components/home/CTA/CTA";
import LayoutSection from "../../../components/common/LayoutSection/LayoutSection";
import styles from "./Home.module.css";

function Home() {
  return (
    <>
      <LayoutSection customClasses={[styles.heroSection]}>
        <Hero />
      </LayoutSection>
      <LayoutSection customClasses={[styles.infoVideoSection]}>
        <InfoVideo />
      </LayoutSection>
      <LayoutSection customClasses={[styles.howItWorksSection]}>
        <HowItWorks />
      </LayoutSection>
      <LayoutSection customClasses={[styles.ctaSection]}>
        <CTA />
      </LayoutSection>
    </>
  );
}

export default Home;
