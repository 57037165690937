import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
import ScrollToTop from "./components/common/ScrollToTop/ScrollToTop";
import LandingPagesLayout from "./layouts/LandingPagesLayout/LandingPagesLayout";
import AuthLayout from "./layouts/AuthLayout/AuthLayout";
import AuthRoute from "./routes/AuthRoute/AuthRoute";
import RootRoute from "./routes/RootRoute/RootRoute";
import PrivateRoute from "./routes/PrivateRoute/PrivateRoute";
import BlueprintsRoute from "./routes/BlueprintsRoute/BlueprintsRoute";

function App() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
      <Router>
        <ScrollToTop />
        <SentryRoutes>
          {/* Landing Pages */}
          <Route element={<LandingPagesLayout />}>
            <Route path="/*" element={<RootRoute />} />
          </Route>
          {/* Auth Pages */}
          <Route element={<AuthLayout />}>
            <Route path="/auth/*" element={<AuthRoute />} />
          </Route>
          {/* Private Routes */}
          <Route element={<PrivateRoute />}>
            <Route path="/blueprints/*" element={<BlueprintsRoute />} />
          </Route>
          {/* ---------- */}
        </SentryRoutes>
      </Router>
  );
}

export default App;
