import React from 'react'
import {Navigate} from 'react-router-dom'
// import {Navigate, Outlet} from 'react-router-dom'

function PrivateRoute() {
  // Check if the user exists
    // Show a full page loading spinner during the check

  // If the user exist then redirect to the outlet
  // Else redirect to the sign in page
  // return (
  //   user ? <Outlet /> : <Navigate to="/auth/signin" />
  // )

  return (
    <Navigate to="/" replace/>
  )
}

export default PrivateRoute