import React from 'react'
import styles from './NavContentContainer.module.css';

function NavContentContainer({children}) {
  return (
    <div className={styles.container}>
        {children}
    </div>
  )
}

export default NavContentContainer