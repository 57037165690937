import React from 'react';
import mergeClassNames from '../../../util/mergeClassNames/mergeClassNames';
import styles from './LayoutSection.module.css';

const LayoutSection = ({ children, customClasses, customStyles }) => {
  return (
    <div className={mergeClassNames(styles.layoutSection, customClasses)} style={customStyles}>
      <div className={styles.contentContainer}>
        {children}
      </div>
    </div>
  );
};

export default LayoutSection;




//  example usage:
// const MyPage = () => {
//     const isHighlighted = true;
  
//     return (
//       <LayoutSection
//         customClasses={[styles.myCustomClass, { 'another-class': isHighlighted }]}
//         customStyles={{ marginTop: '20px', padding: '10px' }}
//       >
//         <h1>Welcome to My Page</h1>
//         <p>This is a layout section with custom styling.</p>
//       </LayoutSection>
//     );
//   };
  
//   export default MyPage;