import React from 'react';
import { Link, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';

function CustomLink({ to, children, onClick, ...props }) {
  const location = useLocation();

  const handleClick = (e) => {
    if (location.pathname === to) {
      e.preventDefault(); // Prevent default navigation if already on the same route
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
    }
    
    if (onClick) {
      onClick(e); // Call the external onClick handler if it exists
    }
  };

  return (
    <Link to={to} onClick={handleClick} {...props}>
      {children}
    </Link>
  );
}

CustomLink.propTypes = {
  to: PropTypes.string.isRequired, // The destination link (required)
  children: PropTypes.node.isRequired, // The content inside the link (required)
  onClick: PropTypes.func, // Optional onClick handler passed from outside
};

export default CustomLink;