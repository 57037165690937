import React from "react";
import LayoutSection from "../../../components/common/LayoutSection/LayoutSection";
import { ReactComponent as NotFoundImg } from "../../../assets/images/NotFound.svg";
import ImageWithWrappingContent from "../../../components/common/ImageWithWrappingContent/ImageWithWrappingContent";
import Button from "../../../components/common/Button/Button";
import { useNavigate } from "react-router-dom";
import styles from "./NotFound.module.css";

function NotFound() {
  const navigate = useNavigate();

  const handleNavigateHome = () => {
    navigate("/");
  };

  return (
    <>
      <LayoutSection customClasses={[styles.section]}>
        <ImageWithWrappingContent
          title="Uh Oh, Page Not Found!"
          subText="The page you're looking for doesn't exist. You may have mistyped the address or the page may have moved."
          imageComponent={<NotFoundImg />}
        >
          <Button
            onClick={handleNavigateHome}
            variant="contained"
            fullWidth
            size="large"
            theme="primary"
          >
            Return to Home
          </Button>
        </ImageWithWrappingContent>
      </LayoutSection>
    </>
  );
}

export default NotFound;
