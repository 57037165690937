import React from "react";
import PropTypes from "prop-types";
import { IconExclamationCircle, IconInfoCircle } from "@tabler/icons-react";
import styles from "./FormAlert.module.css";
import mergeClassNames from "../../../util/mergeClassNames/mergeClassNames";

function FormAlert({ type, message }) {
  return (
    <div
      className={mergeClassNames(styles.wrapper, {
        [styles.errorStatus]: type === "error",
        [styles.successStatus]: type === "success",
      })}
    >
      <div className={styles.iconContainer}>
        {type === "error" && <IconExclamationCircle
          size={16}
          stroke={2}
          className={styles.errorIcon}
        />}
        {type === "success" && <IconInfoCircle
          size={16}
          stroke={2}
          className={styles.successIcon}
        />}
      </div>
      <div className={styles.messageContainer}>
        <p className="normal-text-regular">{message ? message : ""}</p>
      </div>
    </div>
  );
}

FormAlert.propTypes = {
  type: PropTypes.oneOf(["error", "success"]).isRequired,
  message: PropTypes.string,
};

export default FormAlert;
