import React, { useState } from "react";
import { IconPlus, IconMinus } from "@tabler/icons-react";
import { COLORS } from "../../../../constants/constants";
import styles from "./NavSectionContainer.module.css";

function NavSectionContainer({ sectionTitle, children, displayContentByDefault = true }) {
  const [isContentVisible, setIsContentVisible] = useState(displayContentByDefault);

  const handleToggle = () => {
    setIsContentVisible(!isContentVisible);
  };

  return (
    <div className={styles.container}>
      <div
        className={`${styles.sectionTitleContainer} ${
          !isContentVisible ? styles.sectionTitleContainerCollapsed : ""
        }`}
        onClick={handleToggle}
      >
        <p className="normal-text-bold">{sectionTitle}</p>
        {isContentVisible ? (
          <IconMinus
            className={styles.icon}
            size={16}
            stroke={4}
            color={COLORS.accent300}
            onClick={handleToggle}
          />
        ) : (
          <IconPlus
            className={styles.icon}
            size={16}
            color={COLORS.accent300}
            stroke={4}
            onClick={handleToggle}
          />
        )}
      </div>
      {isContentVisible && (
        <div className={styles.sectionContentContainer}>{children}</div>
      )}
    </div>
  );
}

export default NavSectionContainer;
