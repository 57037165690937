import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../common/Button/Button";
import StepCard from "./StepCard/StepCard";
import {
  IconDeviceLaptop,
  IconMoneybag,
  IconChartDonut4,
  IconHeartDollar,
} from "@tabler/icons-react";
import { COLORS } from "../../../constants/constants";
import styles from "./HowItWorks.module.css";

function HowItWorks() {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>How It Works</h2>
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => navigate("/newsletter")}
            variant="contained"
            size="large"
            theme="secondary"
          >
            Start Your Free Trial
          </Button>
        </div>
      </div>
      <div className={styles.stepCardContainer}>
        <StepCard
          icon={<IconDeviceLaptop size={32} color={COLORS.primary200} />}
          title="Sign up for free"
          text="Set up your WealthSphere account in just a few clicks and enjoy a 14-day free trial—no credit card required."
        />
        <StepCard
          icon={<IconMoneybag size={32} color={COLORS.primary200} />}
          title="Create your first yearly blueprint"
          text="Build a plan that captures all your expected income and expenses for the year using WealthSphere's intuitive interface."
        />
        <StepCard
          icon={<IconChartDonut4 size={32} color={COLORS.primary200} />}
          title="Review analytics"
          text="Gain valuable insights into your financial health with easy-to-understand analytics and reports."
        />
        <StepCard
          icon={<IconHeartDollar size={32} color={COLORS.primary200} />}
          title="Stay in control"
          text="Take charge of your finances, confidently plan for your long-term goals, and stay on track as you build the future you want."
        />
      </div>
    </div>
  );
}

export default HowItWorks;
