import React from "react";
import PropTypes from "prop-types";
import styles from "./ImageWithWrappingContent.module.css";
import mergeClassNames from "../../../util/mergeClassNames/mergeClassNames";

function ImageWithWrappingContent({ title, subText, children, imageComponent }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.imageWrapper}>
          {imageComponent}
        </div>
        <div className={styles.contentContainer}>
          {(title || subText) && <div className={styles.textContainer}>
            {title && <h1 className={styles.title}>{title}</h1>}
            {subText && <p
              className={mergeClassNames(styles.subText, "normal-text-regular")}
            >
              {subText}
            </p>}
          </div>}
          {children}
        </div>
      </div>
    </div>
  );
}

// Define prop types
ImageWithWrappingContent.propTypes = {
  title: PropTypes.string.isRequired,  // Title must be a string and is required
  subText: PropTypes.string.isRequired,  // SubText must be a string and is required
  children: PropTypes.node,  // Children can be any renderable node
  imageComponent: PropTypes.element.isRequired,  // Must be a valid React element
};

export default ImageWithWrappingContent;