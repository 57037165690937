import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_COLLECTION_API_BASE_URL;

export async function collectEmail(email, fname, lname) {
  // Input validation
  if (!email || !fname || !lname) {
    throw new Error('All fields (email, fname, lname) are required.');
  }

  const url = `${API_BASE_URL}/collection`;

  try {
    const response = await axios.put(url, { email, fname, lname }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    // Log general error information
    console.error('There was a problem with the request:', error.message);

    if (error.response) {
      // Server responded with a status other than 2xx
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      throw new Error(`HTTP error! Status: ${error.response.status} - ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      // Request was made but no response was received
      console.error('Request details:', error.request);
      throw new Error('No response received from the server.');
    } else {
      // Something else happened in setting up the request
      console.error('Error message:', error.message);
      throw new Error(`Error in setting up the request: ${error.message}`);
    }
  }
}

/**
 * Confirms the user account with a verification code.
 * 
 * @param {string} email - The email address associated with the account.
 * @param {string} verificationCode - The verification code provided to the user.
 * @throws {Error} If either email or verification code is missing, or if the server returns an error.
 * @returns {object} The response data from the server, if successful.
 */
export async function customConfirmAccount(email, verificationCode) {
  // Input validation
  if (!email || !verificationCode) {
    throw new Error('Both email and verificationCode are required.');
  }

  const url = `${API_BASE_URL}/confirmaccount`;

  try {
    const response = await axios.post(url, { email, verificationCode }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      // Re-throw the server error response as-is
      throw error.response.data;
    } else if (error.request) {
      // Request was made but no response was received
      throw new Error('No response received from the server.');
    } else {
      // Something else happened in setting up the request
      throw new Error(`Error in setting up the request: ${error.message}`);
    }
  }
}

/**
 * Sends a request to resend the verification code to the user's email.
 * 
 * @param {string} email - The email address to send the verification code to.
 * @throws {Error} If the email is not provided or if the server returns an error.
 * @returns {object} The response data from the server, if successful.
 */
export async function customResendCode(email) {
  // Input validation
  if (!email) {
    throw new Error('Email is required.');
  }

  const url = `${API_BASE_URL}/resendcode`;

  try {
    const response = await axios.post(url, { email }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      // Re-throw the server error response as-is
      throw error.response.data;
    } else if (error.request) {
      // Request was made but no response was received
      throw new Error('No response received from the server.');
    } else {
      // Something else happened in setting up the request
      throw new Error(`Error in setting up the request: ${error.message}`);
    }
  }
}

/**
 * Checks the confirmation status of a user account.
 * 
 * @param {string} email - The email address to check the confirmation status for.
 * @throws {Error} If the email is missing or if the server returns an error.
 * @returns {object} The response data from the server, if successful.
 */
export async function checkAccountConfirmationStatus(email) {
  // Input validation
  if (!email) {
    throw new Error('Email is required.');
  }

  const url = `${API_BASE_URL}/checkaccountconfirmationstatus`;

  try {
    const response = await axios.post(url, { email }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    // Log general error information
    console.error('There was a problem with the request:', error.message);

    if (error.response) {
      // Server responded with a status other than 2xx
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      throw new Error(`HTTP error! Status: ${error.response.status} - ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      // Request was made but no response was received
      console.error('Request details:', error.request);
      throw new Error('No response received from the server.');
    } else {
      // Something else happened in setting up the request
      console.error('Error message:', error.message);
      throw new Error(`Error in setting up the request: ${error.message}`);
    }
  }
}