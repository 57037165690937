import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LINKS } from "../../../constants/constants"
import Button from "../../common/Button/Button";
import { ReactComponent as PlayVideo } from "../../../assets/images/PlayVideoThumbnail520x368.svg";
import ModalVideo from "../../common/ModalVideo/ModalVideo";
import styles from "./InfoVideo.module.css";

function InfoVideo() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleOpenModal() {
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  function handleNavigate() {
    navigate("/newsletter");
  }

  return (
    <>
      <div className={styles.infoVideoContainer}>
        <PlayVideo
          className={styles.playVideoImage}
          onClick={handleOpenModal}
        />
        <div className={styles.contentContainer}>
          <h2>Money Doesn't Have to Be Hard</h2>
          <p className={["normal-text-regular"]}>
            Create a plan that works for you.
          </p>
          <Button
            onClick={handleNavigate}
            variant="outlined"
            size="large"
            theme="secondary"
          >
            Start Your Free Trial
          </Button>
        </div>
      </div>
      <ModalVideo
        videoLink={LINKS.wealthsphereIntroVideo}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
    </>
  );
}

export default InfoVideo;
