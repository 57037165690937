import React from "react";
import { Outlet } from "react-router-dom";
import LayoutSection from "../../components/common/LayoutSection/LayoutSection";
import LandingPageNav from "../../components/LandingPageNav/LandingPageNav";
import FooterCopyrightBar from "../../components/FooterCopyrightBar/FooterCopyrightBar";
import LandingPageFooter from "../../components/LandingPageFooter/LandingPageFooter";
import styles from "./LandingPagesLayout.module.css";

function LandingPagesLayout() {
  return (
    <div className={styles.pageWrapper}>
      <header className={styles.header}>
        <LayoutSection customClasses={[styles.navSection]}>
          <LandingPageNav />
        </LayoutSection>
      </header>
      <main className={styles.main}>
        <div className={styles.mainContentContainer}><Outlet /></div>
      </main>
      <footer className={styles.footer}>
        <LayoutSection customClasses={[styles.footerSection]}>
          <LandingPageFooter />
        </LayoutSection>
        <LayoutSection customClasses={[styles.footerCopyrightBarSection]}>
          <FooterCopyrightBar />
        </LayoutSection>
      </footer>
    </div>
  );
}

export default LandingPagesLayout;
