import React from "react";
import { ReactComponent as Logo } from "../../assets/logos/WealthSphereLogoAndName.svg";
import {
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandYoutube,
} from "@tabler/icons-react";
import { COLORS, SOCIALS } from "../../constants/constants";
import CustomLink from "../common/CustomLink/CustomLink";
import styles from "./LandingPageFooter.module.css";

function LandingPageFooter() {
  return (
    <div className={styles.container}>
      <div className={styles.companyInfo}>
        <CustomLink to="/">
          <Logo className={styles.logo} />
        </CustomLink>
        <div className={styles.socialMediaLinks}>
          <a
            href={SOCIALS.instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconBrandInstagram
              className={styles.icon}
              size={32}
              color={COLORS.accent300}
            />
          </a>
          <a
            href={SOCIALS.linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconBrandLinkedin
              className={styles.icon}
              size={32}
              color={COLORS.accent300}
            />
          </a>
          <a
            href={SOCIALS.youtube}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconBrandYoutube
              className={styles.icon}
              size={32}
              color={COLORS.accent300}
            />
          </a>
        </div>
      </div>
      <div className={styles.linkSectionContainer}>
        <div className={styles.linkSection}>
          <h4>Explore</h4>
          <CustomLink to="/" className={["normal-text-regular"]}>
            Home
          </CustomLink>
          <CustomLink to="/pricing" className={["normal-text-regular"]}>
            Pricing
          </CustomLink>
          <CustomLink to="/newsletter" className={["normal-text-regular"]}>
            Newsletter
          </CustomLink>
        </div>
        {/* <div className={styles.linkSection}>
          <h4>Legal</h4>
          <CustomLink to="/" className={["normal-text-regular"]}>
            Terms
          </CustomLink>
          <CustomLink to="/" className={["normal-text-regular"]}>
            Privacy Policy
          </CustomLink>
        </div>
        <div className={styles.linkSection}>
          <h4>Company</h4>
          <CustomLink to="/" className={["normal-text-regular"]}>
            Contact Us
          </CustomLink>
        </div> */}
      </div>
    </div>
  );
}

export default LandingPageFooter;
