import { useState, useEffect } from 'react';
import { BREAKPOINTS } from '../../constants/constants';

function useViewport() {
    const [viewport, setViewport] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
        breakpoint: getBreakpoint(window.innerWidth),
    });

    function getBreakpoint(width) {
        if (width >= BREAKPOINTS.XS.min && width <= BREAKPOINTS.XS.max) {
            return 'XS';
        } else if (width >= BREAKPOINTS.S.min && width <= BREAKPOINTS.S.max) {
            return 'S';
        } else if (width >= BREAKPOINTS.M.min && width <= BREAKPOINTS.M.max) {
            return 'M';
        } else if (width >= BREAKPOINTS.L.min) {
            return 'L';
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setViewport({
                width: window.innerWidth,
                height: window.innerHeight,
                breakpoint: getBreakpoint(window.innerWidth),
            });
        };

        window.addEventListener('resize', handleResize);

        // Cleanup listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return viewport;
}

export default useViewport;