import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import Home from '../../pages/landingPages/Home/Home';
import NotFound from '../../pages/landingPages/NotFound/NotFound';
import Newsletter from '../../pages/landingPages/Newsletter/Newsletter';

function RootRoute() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/newsletter" element={<Newsletter />} />
      <Route path="/pricing" element={<Navigate to="/newsletter" replace />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}

export default RootRoute;