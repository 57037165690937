import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styles from "./ModalVideo.module.css";

const ModalVideo = ({ videoLink, isOpen, onClose }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <iframe
          className={styles.responsiveIframe}
          src={videoLink}
          allowFullScreen
          title="Video"
        ></iframe>
      </div>
    </div>,
    document.getElementById("modal")
  );
};

ModalVideo.propTypes = {
  videoLink: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalVideo;