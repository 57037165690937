import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../../assets/logos/WealthSphereLogoAndName.svg";
import { IconX } from "@tabler/icons-react";
import { COLORS } from "../../../../constants/constants";
import styles from "./FullPageModalNav.module.css";

function FullPageModalNav({ onMenuCloseClick, homeLocation="/" }) {
  return (
    <div className={styles.container}>
      <div>
        <Link onClick={onMenuCloseClick} to={homeLocation}>
          <Logo className={styles.logo} />
        </Link>
      </div>
      <IconX
        className={styles.icon}
        size={32}
        color={COLORS.accent300}
        stroke={4}
        onClick={onMenuCloseClick}
      />
    </div>
  );
}

export default FullPageModalNav;
