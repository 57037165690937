import React from 'react'
import styles from "./StepCard.module.css";

function StepCard({icon, title, text}) {
  return (
    <div className={styles.container}>
      <div className={styles.iconAndTitle}>
        <div className={styles.icon}>{icon}</div>
        <h4>{title}</h4>
      </div>
      <div className={styles.textContainer}>
        <p className={['normal-text-regular']}>{text}</p>
      </div>
    </div>
  )
}

export default StepCard;