import React from "react";
import { ReactComponent as Logo } from "../../../assets/logos/WealthSphereLogoAndName.svg";
import {
  IconMenu2,
} from "@tabler/icons-react";
import { COLORS } from "../../../constants/constants";
import CustomLink from "../../common/CustomLink/CustomLink";
import styles from "./LandingPageMobileNav.module.css";

function LandingPageMobileNav({onMenuClick}) {

  return (
    <div className={styles.container}>
      <div>
        <CustomLink to="/">
          <Logo className={styles.logo} />
        </CustomLink>
      </div>
        <IconMenu2
          className={styles.icon}
          size={32}
          color={COLORS.accent300}
          onClick={onMenuClick}
        />
    </div>
  );
}

export default LandingPageMobileNav;
