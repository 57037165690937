import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants/constants';
import styles from './FooterCopyrightBar.module.css';

function FooterCopyrightBar({ textColor = 'accent300' }) {
  // Determine the color based on the textColor prop
  const color = textColor === 'primary300' 
    ? COLORS.primary300 
    : COLORS.accent300;

  return (
    <div className={styles.container}>
      <p 
        style={{ color: color }}
        className="normal-text-bold"
      >
        © {new Date().getFullYear()} WealthSphere™ LLC. All rights reserved.
      </p>
    </div>
  );
}

FooterCopyrightBar.propTypes = {
  textColor: PropTypes.oneOf(['primary300', 'accent300']),
};

export default FooterCopyrightBar;