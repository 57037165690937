export const COLORS = {
  primary100: "#A3B8C4",
  primary200: "#3498DB",
  primary300: "#2C3E50",
  primary400: "#1A2B3C",

  secondary100: "#A8D5C0",
  secondary200: "#1ABC9C",
  secondary300: "#16A085",

  accent100: "#ECF0F1",
  accent200: "#262626",
  accent300: "#FFFFFF",

  accentB: "#F0ECDD",
  accentC: "#FFEB7A",

  error100: "#F9D5D3",
  error200: "#F1948A",
  error300: "#E74C3C",

  success100: "#D4EFDF",
  success200: "#58D68D",
  success300: "#27AE60",
  
  warning300: "#F39C12",
};

export const TYPOGRAPHY = {
  // Headings
  h1: {
    fontSize: '56px',
    lineHeight: '61.6px',
    fontWeight: 700,
    fontFamily: '"Poppins", Helvetica, sans-serif',
  },
  h2: {
    fontSize: '48px',
    lineHeight: '52.8px',
    fontWeight: 700,
    fontFamily: '"Poppins", Helvetica, sans-serif',
  },
  h3: {
    fontSize: '40px',
    lineHeight: '44px',
    fontWeight: 700,
    fontFamily: '"Poppins", Helvetica, sans-serif',
  },
  h4: {
    fontSize: '32px',
    lineHeight: '35.2px',
    fontWeight: 700,
    fontFamily: '"Poppins", Helvetica, sans-serif',
  },
  h5: {
    fontSize: '24px',
    lineHeight: '26.4px',
    fontWeight: 700,
    fontFamily: '"Poppins", Helvetica, sans-serif',
  },
  h6: {
    fontSize: '20px',
    lineHeight: '22px',
    fontWeight: 700,
    fontFamily: '"Poppins", Helvetica, sans-serif',
  },

  // Body Text
  largeTextBold: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 700,
    fontFamily: '"DM Sans", sans-serif',
  },
  largeTextRegular: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 400,
    fontFamily: '"DM Sans", sans-serif',
  },
  mediumTextBold: {
    fontSize: '18px',
    lineHeight: '25.2px',
    fontWeight: 700,
    fontFamily: '"DM Sans", sans-serif',
  },
  mediumTextRegular: {
    fontSize: '18px',
    lineHeight: '25.2px',
    fontWeight: 400,
    fontFamily: '"DM Sans", sans-serif',
  },
  normalTextBold: {
    fontSize: '16px',
    lineHeight: '22.4px',
    fontWeight: 700,
    fontFamily: '"DM Sans", sans-serif',
  },
  normalTextRegular: {
    fontSize: '16px',
    lineHeight: '22.4px',
    fontWeight: 400,
    fontFamily: '"DM Sans", sans-serif',
  },
  smallTextBold: {
    fontSize: '14px',
    lineHeight: '19.6px',
    fontWeight: 700,
    fontFamily: '"DM Sans", sans-serif',
  },
  smallTextRegular: {
    fontSize: '14px',
    lineHeight: '19.6px',
    fontWeight: 400,
    fontFamily: '"DM Sans", sans-serif',
  },
};

export const BREAKPOINTS = {
  XS: { min: 0, max: 479 },
  S: { min: 480, max: 799 },
  M: { min: 800, max: 991 },
  L: { min: 992, max: Infinity }, // No upper limit for large screens
};

export const SOCIALS = {
  instagram: "https://www.instagram.com/wealthsphereapp",
  linkedin: "https://www.linkedin.com/company/wealthsphere/",
  youtube: "https://www.youtube.com/@wealthsphereapp"
};

export const LINKS = {
  wealthsphereIntroVideo: "https://www.youtube.com/embed/3RNUBZdRUsY?si=-LLsiQXupT8gYwOc"
}